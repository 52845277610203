// pages/_app.js
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React, { useEffect } from 'react'
import config from 'src/config'

type IProps = {
  children: React.ReactNode
}

const Provider: React.FC<IProps> = ({ children }) => {
  useEffect(() => {
    if (!config.isProd) return

    posthog.init(config.posthog.key, {
      api_host: '/ingest',
      ui_host: 'https://us.posthog.com',
      person_profiles: 'always',
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug()
      }
    })
  }, [])

  if (!config.isProd) return <>{children}</>
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

export default React.memo(Provider)
