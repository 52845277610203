import config from '../config'

const FacebookScript = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.defer=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', ${config.pixels.fb});`

const GtagScript = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${config.pixels.ga}', { page_path: window.location.pathname });
window.gtag = gtag;
`

const LinkedInScript1 = `
_linkedin_partner_id = "6688970";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
`

const LinkedInScript2 = `
(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);`

const ConsoleMessage = `
\u2764️  Welcome to SpotMe \u2764️ 

   *****    *****
  ******* ********
 ******************
  ****************
   *************
    **********
      ******
        **

Join us and build a world where anyone can store anywhere at https://www.linkedin.com/company/spotmemx
`

export {
  ConsoleMessage,
  FacebookScript,
  GtagScript,
  LinkedInScript1,
  LinkedInScript2
}
